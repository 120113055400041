<template>
    <div
        :id="id"
        v-click-outside="handleClickOutside"
        class="field field-autocomplete field-text"
        :class="{
            'is-active': isActive,
            'is-invalid': isInvalid,
            'field-underlined': underlined,
            'is-readonly': readonly,
            'is-disabled': disabled
        }">
        <div class="hstack justify-content-between">
            <label
                v-if="label && !formFloating"
                :id="labelId"
                :for="formControlId"
                class="form-label">
                {{ label }}
                <span v-if="mandatory" class="text-danger ms-1">*</span>
            </label>
            <slot name="label-end"></slot>
        </div>
        <div class="form-control-wrapper">
            <div @click="focusToInput"
                class="field-selection"
                :class="{
                    'form-floating': formFloating,
                    'field-selection-chips': chips,
                    'is-disabled': disabled
                }">
                <slot name="field-selection"
                    :fieldValue="fieldValue"
                    :focusIndex="selectionFocusIndex"
                    :onMouseDown="handleMouseDown"
                    :getItemText="getItemText"
                    :setSelectionFocusIndex="(value: number) => selectionFocusIndex = value">
                    <span v-for="(item, index) in fieldValue"
                        v-if="!chips"
                        class="field-selection-item"
                        :class="{
                            'is-focused': selectionFocusIndex === index,
                            'field-selection-item-chip': chips
                        }"
                        @click="selectionFocusIndex = index"
                        @mousedown="handleMouseDown">
                        <span>{{ getItemText(item) }}</span>
                    </span>
                    <FieldSelectionChips
                        v-else
                        :items="fieldValue"
                        :get-item-text="getItemText"
                        :handle-mouse-down="handleMouseDown"
                        :focus-index="selectionFocusIndex"
                        :handle-item-delete="(item) => selectItem(item)"
                        :limit="chipLimit"
                        @item-click="selectionFocusIndex = $event"
                    ></FieldSelectionChips>
                </slot>
                <input
                    ref="inputRef"
                    :id="formControlId"
                    :class="{ 'has-value': hasValue }"
                    :name="name"
                    :placeholder="placeholder"
                    :aria-labelledby="[labelId, formControlId].join(' ')"
                    class="form-control"
                    type="text"
                    aria-haspopup="listbox"
                    autocomplete="off"
                    :disabled="disabled"
                    :readonly="readonly"
                    @click.prevent="activateMenu"
                    @keyup="handleKeyup"
                    @keydown="handleKeyDown"
                    @focus="activateMenu"
                    @blur="handleClickOutside">
                <label v-if="label && formFloating" :id="labelId" :for="formControlId" class="dropdown-label mb-2">
                    <slot name="label">{{ label }}</slot>
                </label>
                <Icon symbol="chevron-down" class="icon-toggle"></Icon>
            </div>
            <button v-if="clearable && modelValue"
                @click="clear"
                class="btn btn-overlay-primary btn-clear p-1 rounded-circle"
                title="Clear input"
                type="button">
                <Icon symbol="x"></Icon>
            </button>
        </div>
        <div class="menu" :class="{ 'is-active': isActive }">
            <ul class="menu-list"
                :id="listId"
                tabindex="-1"
                role="listbox"
                :aria-labelledby="labelId">
                <li v-for="(item, index) in getListItems()"
                    :ref="setOptionItemRefs"
                    class="list-item"
                    :class="{
                        'is-selected': isItemSelected(item),
                        'is-focused': listItemFocusIndex === index
                    }"
                    :aria-selected="getItemValue(item) === modelValue ? 'true' : 'false'"
                    @click="handleOptionClick(item, !multiple)"
                    @mousedown="handleMouseDown">
                    <slot name="list-item" :item="item" :itemText="getItemText(item)" :isSelected="isItemSelected(item)">
                        <span class="list-item-text">{{ getItemText(item) }}</span>
                    </slot>
                </li>
                <li v-if="getListItems().length === 0" class="list-item">{{ noDataText }}</li>
            </ul>
        </div>
        <p v-if="hint" class="hint-message caption text-muted mt-1">{{ hint }}</p>
        <p v-if="errorMessage" class="error-message caption text-danger">{{ errorMessage }}</p>
    </div>
</template>

<script lang="ts" setup>
import { FieldSelectionChips, Icon } from '@/modules/core/components';
import { ClickOutside as vClickOutside } from "@/modules/core/directives";
import { nextTick, onBeforeUpdate, ref, PropType, computed } from "vue";
import commonProps from "./props";

const props = defineProps({
    ...commonProps,
    modelValue: {
        type: [Object, String, Array, Number] as PropType<any>
    },
    formFloating: Boolean,
    name: String,
    isInvalid: Boolean,
    errorMessage: String,
})

const emit = defineEmits(['update:modelValue', 'search', 'clear'])

const labelId = computed(() => "label-" + props.id);

const formControlId = computed(() => "button-" + props.id);

const listId = computed(() => "list-" + props.id);

const placeholder = computed(() => {
    if (props.multiple && Array.isArray(props.modelValue) && props.modelValue.length > 0) {
        return '';
    }
    if (typeof props.modelValue === 'number') return '';
    if (!props.multiple && props.modelValue) {
        return '';
    }
    return props.placeholder;
})

const hasValue = computed(() => {
    if (props.multiple) {
        if (!Array.isArray(props.modelValue)) return false;
        if (props.modelValue.length === 0) return false;
    }
    return !!props.modelValue;
});

const inputRef = ref<HTMLInputElement|null>(null);
        

const isActive = ref(false);
const selectionFocusIndex = ref(-1);
const listItemFocusIndex = ref(-1);
const inputValue = ref('');
// cachedItems maintains the list of original items
// that is selected as modelValue represents an array of primitives
const cachedItems = ref(props.cacheItems ? [...props.items] : [])

let optionItems: Array<HTMLLIElement> = [];
const setOptionItemRefs = (el: any) => el && optionItems.push(el as HTMLLIElement);
onBeforeUpdate(() => optionItems = [])

const getItemText = (item?: any) => {
    if (item === null) return '';
    if (item === undefined) return '';
    if (['string', 'number'].includes(typeof item)) return item;
    if (!props.itemText) return '';
    return item[props.itemText as keyof typeof item];
}

const getItemValue = (item?: string|number|object) => {
    if (item === null) return '';
    if (item === undefined) return '';
    if (['string', 'number'].includes(typeof item)) return item;
    return item[props.itemValue as keyof typeof item]
}

const fieldValue = computed(() => {
    const modelValue = props.modelValue;
    if (modelValue === null) return [];
    if (modelValue === undefined) return [];
    if (!props.multiple && ['string', 'number'].includes(typeof modelValue)) {
        const allItems = [...props.items];
        if (props.cacheItems) {
            allItems.push(...cachedItems.value)
        }

        const selectedItem = allItems?.find(x => getItemValue(x) === getItemValue(modelValue));
        if (selectedItem) {
            commitToCache([selectedItem])
        }
        return selectedItem ? [selectedItem] : [];
    }
    if (!Array.isArray(modelValue)) return [];
    const selectedItems = modelValue.map(x => [...props.items, ...cachedItems.value].find(y => getItemValue(y) === x))
    if (selectedItems.length > 0) {
        commitToCache(selectedItems)
    }
    return selectedItems
})

const commitToCache = (items: Array<any>) => {
    if (!props.cacheItems) return;
    for (let item of items) {
        const itemValue = getItemValue(item);
        const inCacheIndex = cachedItems.value.findIndex(x => getItemValue(x) === itemValue);
        if (inCacheIndex === -1) {
            cachedItems.value.push(item)
        }
    }
}

const getListItemNextFocusIndex = (focusIndex = listItemFocusIndex.value + 1) => {
    return focusIndex === props.items.length ? 0 : focusIndex
}

const getListItemPrevFocusIndex = (focusIndex = listItemFocusIndex.value - 1) => {
    return focusIndex < 0 ? props.items.length - 1 : focusIndex
}

const getChipPrevFocusIndex = (focusIndex = selectionFocusIndex.value - 1) => {
    if (props.multiple && Array.isArray(props.modelValue)) {
        return focusIndex < 0 ? props.modelValue.length - 1 : focusIndex;
    } else {
        return focusIndex === -1 ? 0 : -1
    }
}

const getChipNextFocusIndex = (focusIndex = selectionFocusIndex.value + 1) => {
    if (props.multiple && Array.isArray(props.modelValue)) {
        return focusIndex === props.modelValue.length ? 0 : focusIndex;
    } else {
        return focusIndex === -1 ? 0 : -1
    }
}

const getListItems = () => {
    return !props.noFilter 
        ? props.items.filter(x => new RegExp(sanitize(inputValue.value), 'gi').test(getItemText(x) ?? ''))
        : props.items;
}

const sanitize = (term: string) => {
    if (!term) return term;
    return term.replace(/[\\^$*+?.()|{}[]/g, '\\$&');
}

const isItemSelected = (item: string|Object) => {
    if (!props.multiple) {
        return getItemValue(item) === props.modelValue;
    } else {
        return Array.isArray(props.modelValue) && props.modelValue.includes(getItemValue(item));
    }
}

const clearInput = () => {
    if (!!inputValue.value) {
        inputValue.value = '';
        inputRef.value && (inputRef.value.value = '');
        emit('search', inputValue.value);
    }
}

const handleClickOutside = () => {
    isActive.value = false;
    listItemFocusIndex.value = -1;
    selectionFocusIndex.value = -1;
    clearInput()
}

const activateMenu = () => {
    isActive.value = true;
    optionItems[0] && optionItems[0].scrollIntoView({ block: 'nearest' }) 
}

const selectItem = (item: string|object|number) => {
    const oldValue = props.modelValue;
    const value = props.returnObject ? item : getItemValue(item);
    if (!props.multiple) {
        clearInput();
        return emit('update:modelValue', value === oldValue ? undefined : value);
    }

    const modelValue = props.modelValue;
    let newArray = Array.isArray(modelValue) ? modelValue : [];
    if (!isItemSelected(item)) {
        newArray.push(value);
    } else {
        const itemValueIndex = newArray.findIndex(x => x === value);
        if (itemValueIndex !== -1) {
            newArray.splice(itemValueIndex, 1);
        }
    }
    newArray = newArray.filter(
        (value, index, array) => array.indexOf(value) === index
    )
    clearInput();
    emit('update:modelValue', newArray)
}

const handleOptionClick = (item: string|object|number, hideMenu = true) => {
    const value = props.returnObject ? item : getItemValue(item);
    if (props.cacheItems && item) {
        commitToCache([item])
    }
    selectItem(value);

    nextTick(() => {
        if (hideMenu) {
            isActive.value = false;
            listItemFocusIndex.value = -1;
        }
        selectionFocusIndex.value = -1;
        inputValue.value = '';
    })
}

const handleKeyDown = (event: KeyboardEvent) => {
    switch (event.key) {
        case 'Enter':
            event.preventDefault()
            break;
    }
}

const handleKeyup = (event: KeyboardEvent) => {
    if (!props.items) return;
    switch (event.key) {
        case 'Enter':
            event.preventDefault()
            const item = getListItems()[listItemFocusIndex.value];
            if (item) {
                selectItem(item);
                clearInput()
            }
            break;
        case 'ArrowDown':
            event.preventDefault();
            if (!isActive.value) isActive.value = true;
            selectionFocusIndex.value = -1;
            listItemFocusIndex.value = getListItemNextFocusIndex();
            optionItems[listItemFocusIndex.value]?.scrollIntoView({ block: 'nearest', behavior: 'smooth' })
            break;
        case 'ArrowUp':
            event.preventDefault();
            if (!isActive.value) isActive.value = true;
            selectionFocusIndex.value = -1;
            listItemFocusIndex.value = getListItemPrevFocusIndex();
            optionItems[listItemFocusIndex.value]?.scrollIntoView({ block: 'nearest', behavior: 'smooth' })
            break;
        case 'ArrowLeft':
            event.preventDefault();
            selectionFocusIndex.value = getChipPrevFocusIndex()
            break;
        case 'ArrowRight': 
            event.preventDefault();
            selectionFocusIndex.value = getChipNextFocusIndex();
            break;
        case 'Backspace':
            event.preventDefault()
            inputValue.value = (event.target as HTMLInputElement).value;
            emit('search', inputValue.value);
            if (props.multiple && Array.isArray(props.modelValue)) {
                if (!inputValue.value) {
                    if (selectionFocusIndex.value === -1) {
                        selectionFocusIndex.value = props.modelValue.length - 1;
                    } else {
                        const newArray = [...props.modelValue];
                        newArray.splice(selectionFocusIndex.value, 1);
                        selectionFocusIndex.value = Math.min(selectionFocusIndex.value, newArray.length - 1);
                        emit('update:modelValue', newArray);
                    }
                }
            } else {
                if (!inputValue.value) {
                    emit('update:modelValue', '');
                    if (props.cacheItems) {
                        const cacheItemIndex = cachedItems.value.find(x => getItemValue(x) === props.modelValue);
                        if (cacheItemIndex !== -1) {
                            cachedItems.value.splice(cacheItemIndex, 1);
                        }
                    }
                }
            }
            break;
        case 'Escape':
            selectionFocusIndex.value = -1;
            listItemFocusIndex.value = -1;
            isActive.value = false;
            break;
        default: 
            event.preventDefault()
            inputValue.value = (event.target as HTMLInputElement).value;
            emit('search', inputValue.value)
            selectionFocusIndex.value = -1;
            listItemFocusIndex.value = -1;
            break;
    }
}

const focusToInput = () => inputRef.value && inputRef.value.focus();

// Prevent blur
const handleMouseDown = (e: Event) => e.preventDefault();

const clear = () => {
    if (props.multiple) {
        emit('update:modelValue', [])
    } else {
        emit('update:modelValue', '')
    }
    emit('clear')
}

</script>

<style lang="scss">

.field-autocomplete {
    flex: 1 1 auto;
    position: relative;

    .menu {
        width: 100%;
    }

    .form-control {
        cursor: pointer;
        min-width: 0;
        flex: 1 1;
        border-color: transparent;
        padding: 0;
        outline: none;
        border-radius: 0;
        background-color: transparent;
        border: 0;

        &:disabled, &.disabled {
            border: 0;
            padding: 0;
            cursor: default;
        }

        &:focus, &:active, &:hover {
            box-shadow: none;
            border-color: transparent;
        }
    }

    .icon-toggle {
        color: $secondary;
        transition: .3s ease;
        transition-property: transform, transform-origin;
        position: absolute;
        top: 50%;
        left: auto;
        right: 1rem;
        transform: translateY(-50%);
    }

    .field-selection {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        border-radius: $input-border-radius;
        color: $input-color;
        border: $input-border-width solid $input-border-color;
        padding: 0.375rem 2rem 0.375rem 0.75rem;
        position: relative;
        gap: 0.2rem;
        outline: 2px solid transparent;
        outline-offset: -2px;
        transition: $input-transition;

        &.form-floating {
            padding: 0;

            .field-selection-item {
                padding-top: $form-floating-input-padding-t;
                padding-bottom: $form-floating-input-padding-b;

                &:first-of-type {
                    padding-left: $form-floating-padding-x;
                }
            }

            .form-control {
                border-radius: $input-border-radius;

                &:first-child {
                    padding-left: $form-floating-padding-x;
                }

                &::placeholder {
                    color: transparent
                }

                &.has-value ~ label {
                    opacity: $form-floating-label-opacity;
                    transform: $form-floating-label-transform;
                }
            }

            &.field-selection-chips {
                padding: $form-floating-padding-y $form-floating-padding-x;
                padding-top: $form-floating-input-padding-t;
                padding-bottom: 0.5rem;

                .field-selection-item {
                    padding-top: 0.1rem;
                    padding-bottom: 0.1rem;
                }

                .form-control {
                    padding: 0;
                    padding-top: .25rem;
                    height: calc(1.25rem + 2px);
                    border-radius: 0;
                }
            }
        }

        &-item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            transition: .15s ease;
            transition-property: color, background-color;
            max-width: 97%;
            line-height: 1;

            &::after {
                content: ',';
            }

            &:last-of-type::after {
                content: '';
            }

            &.is-focused {
                color: $secondary;
            }

            &-chip {
                background-color: rgba($secondary, 0.1);
                border-radius: 15px;
                font-size: 12px;
                padding: 0.2rem 0.75rem;
                cursor: pointer;
 
                &::after {
                    display: none;
                }

                &.is-focused {
                    color: $input-color;
                    background-color: rgba($secondary, 0.2);
                }
            }

            &-text {
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }

            &-remove-cta {
                flex-shrink: 0;
                width: 18px;
                height: 18px;
                margin-left: 8px;
                padding: 0;
                border: 0;
                border-radius: 50%;
                background: $black;
                color: $white;

                &:hover {
                    background: rgba($black, 0.7);
                }
            }
        }

        &:focus-within {
            outline-color: $primary;
            box-shadow: $input-focus-box-shadow;
        }

        @include media-breakpoint-up (md) {
            &:hover {
                border-color: $gray-500;
            }
        }
    }

    .btn-clear {
        position: absolute;
        top: 50%;
        right: 2rem;
        transform: translateY(-50%);
    }

    &.is-active {
        .icon-toggle {
            transform: translateY(-50%) rotate(-180deg);
            transform-origin: center;
        }

        .form-control {
            min-width: 2rem;
        }
    }

    &.is-invalid {
        .field-selection {
            border-color: $danger;

            &:focus-within {
                outline-color: $danger;
                box-shadow: 0 0 $input-btn-focus-blur $input-focus-width rgba($danger, $input-btn-focus-color-opacity);
            }
        }
    }

    &.is-disabled {
        .field-selection {
            background-color: $input-disabled-bg;
        }
    }

    &.is-readonly {
        .field-selection {
            pointer-events: none;
            background-color: initial;
        }

        .icon-toggle {
            visibility: hidden;
        }
    }

    &.field-underlined {
        .field-selection {
            border: 0;
            outline: 0;
            box-shadow: none;
            padding-left: 0;
            border-radius: 0;
        }

        &.is-invalid:focus-within {
            .field-selection {
                box-shadow: none;
            }
        }
    }
}
</style>