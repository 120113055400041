import { PropType } from "vue";

export default {
    id: String,
    label: String,
    placeholder: {
        type: String,
        default: 'Please Select'
    },
    items: {
        type: Array as PropType<any[]>,
        default: () => [],
        required: true
    },
    itemText: String,
    itemValue: String,
    returnObject: Boolean,
    multiple: Boolean,
    noDataText: {
        type: String,
        default: 'No data available'
    },
    cacheItems: {
        type: Boolean,
        default: true
    },
    chips: Boolean,
    noFilter: Boolean,
    clearable: Boolean,
    disabled: Boolean,
    hint: String,
    mandatory: Boolean,
    underlined: Boolean,
    readonly: Boolean,
    chipLimit: {
        type: Number,
        default: 3
    }
}